import tagCloud from 'TagCloud'

let el = document.getElementById('tagcloud') as HTMLElement
let tags = JSON.parse(el.getAttribute('data-tags') || '[]')

// Radius also change in welcome.scss
tagCloud(el, tags, {radius: 150, maxSpeed: 'slow'})
el.addEventListener('click', e => {
    let tag = e.target as HTMLElement
    if (tag.className === 'tagcloud--item') {
        location.href = `/tags/${tag.innerText}`
    }
})